<template>
	<LayoutDefaultNew>
		<div><SubNavbar page="Delivery" /></div>
		<div>
			<RouteListHeaders />
		</div>
		<div>
			<GetUndeliveredDispatchesData />
		</div>
		<div>
			<DispatchesAddedToRouteList />
		</div>
		<div>
			<UndeliveredDispatchesToAddToRouteList />
		</div>
		<div>
			<SaveTheRouteList />
		</div>
	</LayoutDefaultNew>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import RouteListHeaders from '@/_srcv2/pages/deliveries-route-lists/add-extract-dispatch/components/RouteListHeaders.vue'
import GetUndeliveredDispatchesData from '@/_srcv2/pages/deliveries-route-lists/add-extract-dispatch/components/GetUndeliveredDispatchesData.vue'
import DispatchesAddedToRouteList from '@/_srcv2/pages/deliveries-route-lists/add-extract-dispatch/components/DispatchesAddedToRouteList.vue'
import UndeliveredDispatchesToAddToRouteList from '@/_srcv2/pages/deliveries-route-lists/add-extract-dispatch/components/UndeliveredDispatchesToAddToRouteList.vue'
import SaveTheRouteList from '@/_srcv2/pages/deliveries-route-lists/add-extract-dispatch/components/SaveTheRouteList.vue'

export default {
	name: 'AddExtractDispatchToRouteList',
	components: {
		SubNavbar,
		RouteListHeaders,
		GetUndeliveredDispatchesData,
		DispatchesAddedToRouteList,
		UndeliveredDispatchesToAddToRouteList,
		SaveTheRouteList,
	},
	setup() {},
}
</script>

<style lang="scss" scoped></style>
