<template>
	<div></div>
</template>

<script>
export default {
	name: 'DispatchesAddedToRouteList',
	setup() {},
}
</script>

<style lang="scss" scoped></style>
